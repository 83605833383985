<template>
  <el-form label-width="auto" :model="data" :disabled="compile">
    <el-form-item label="姓名">
      <el-input v-model="data.realname" :disabled="contact"></el-input>
    </el-form-item>
    <el-form-item label="昵称">
      <el-input v-model="data.name" :disabled="contact"></el-input>
    </el-form-item>
    <el-form-item label="职位">
      <el-select
        v-model="data.role_id"
        placeholder="请选择员工职位"
        :disabled="contact"
      >
        <el-option
          v-for="(item, idx) in $common.position"
          :key="item"
          :label="item"
          :value="idx + 1"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="等级">
      <el-select
        v-model="data.level"
        placeholder="请选择员工等级"
        :disabled="contact"
      >
        <el-option
          v-for="(item, idx) in $common.levels"
          :key="item"
          :label="item"
          :value="idx + 1"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="部门">
      <el-select
        v-model="data.bumen"
        placeholder="请选择员工部门"
        :disabled="contact"
      >
        <el-option
          v-for="(item, idx) in $common.bumens"
          :key="item"
          :label="item"
          :value="idx + 1"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="联系">
      <el-input v-model="data.mobile"></el-input>
    </el-form-item>
    <el-form-item label="我的邮箱">
      <el-input
        v-if="data.email"
        v-model="data.email"
        :disabled="contact"
      ></el-input>
      <el-input v-else v-model="data.email" :disabled="contact"></el-input>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  props: {
    data: Object,
    compile: Boolean,
    contact: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    data: {
      handler: function() {
        this.data.level *= 1;
        this.data.bumen *= 1;
      },
      deep: true
    }
  }
  // data() {
  // return {
  // account: this.data.email
  // usreData: {}
  // compile: false
  // };
  // },
  // methods: {
  // saveBtn() {
  //   this.$emit("saveBtn", this.usreData);
  // }
  // }
};
</script>

<style lang="less" scoped>
@deep: ~">>>";
@{deep}.el-select {
  width: 100% !important;
}
</style>

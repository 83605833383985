<template>
  <div
    class="classify"
    v-if="getData && !(getData.text == '紧急项目' || getData.text == '迭代项目')"
    @click="screen(getData)"
  >
    <div class="icon_font" :style="'background-image:url(' + getData.url + ')'"></div>
    <p>{{ getData.text }}</p>
    <b class="num">{{ quantity }}</b>
  </div>
</template>
<script>
// 164 112
export default {
  props: { name: [Number, String], quantity: [Number, String] },
  data() {
    return {
      selections: [
        {
          name: "cnt_begin",
          url: require("../assets/img/p1_2.png"),
          text: "正在进行中的项目"
        },
        {
          name: "cnt_pressing",
          url: require("../assets/img/p1_3.png"),
          text: "紧急项目"
        },
        {
          name: "cnt_delay",
          url: require("../assets/img/p1_4.png"),
          text: "延期项目"
        },
        // {
        //   name: "cnt_book",
        //   url: require("../assets/img/p1_5.png"),
        //   text: "预约项目"
        // },
        {
          name: "cnt_type_3",
          url: require("../assets/img/p1_6.png"),
          text: "插画视频的项目"
        },
        {
          name: "cnt_type_2",
          url: require("../assets/img/p1_7.png"),
          text: "短期项目"
        },
        {
          name: "cnt_pause",
          url: require("../assets/img/p1_8.png"),
          text: "暂停项目"
        },
        {
          name: "cnt_end",
          url: require("../assets/img/p1_9.png"),
          text: "已完成的项目"
        },
        {
          name: "cnt_diedai",
          url: require("../assets/img/p1_10.png"),
          text: "迭代项目"
        },
        {
          name: 9,
          url: require("../assets/img/p1_11.png"),
          text: "待反馈项目"
        },
        {
          name: 10,
          url: require("../assets/img/p1_12.png"),
          text: "风险项目"
        },
        {
          name: "finish_per",
          url: require("../assets/img/p1_13.png"),
          text: "完成率"
        },
        {
          name: 12,
          url: require("../assets/img/p1_14.png"),
          text: "待审核项目"
        },
        {
          name: 13,
          url: require("../assets/img/p1_15.png"),
          text: "待提交项目"
        },
        {
          name: 14,
          url: require("../assets/img/p1_16.png"),
          text: "待分配项目"
        }
      ]
    };
  },
  methods: {
    screen(item) {
      console.log(item, "096586094586094580");
      this.$emit("examine", item);
      this.$router.push({ path: "/index",query:{id:item}});
    }
  },
  computed: {
    getData: function() {
      // console.log(this.name, this.quantity);
      for (var item of this.selections) {
        if (this.name === item.name && item.url) {
          // console.log(item);
          return item;
        }
      }
      return null;
    }
  }
};
</script>
<style lang="less" scoped>
.classify {
  //   height: 80px;
  text-align: center;
  // min-width: 80px;
  width: 140px;
  cursor: pointer;
  background: #fff;
  box-shadow: 0 0 10px 1px #ddd;
  border-radius: 7px;
  padding: 5px 0;
  // cursor: pointer;
  .icon_font {
    width: 40px;
    height: 40px;
    background: no-repeat top/100% 100%;
    margin: 0 auto;
    // border-radius: 100%;
  }
  p {
    margin: 3px 0;
    font-size: 12px;
    color: #999;
  }
  .num {
    font-size: 16px;
  }
}
</style>

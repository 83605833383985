<template>
  <AppBar>
    <div class="wrap">
      <div class="one">
        <el-image
          :src="
            data.thumb
              ? `//ghf.site.yscase.com${data.thumb}`
              : '//cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png'
          "
          fit="cover"
        ></el-image>
        <el-button round icon="el-icon-lock" @click="dialogVisible = true"
          >修改密码</el-button
        >
        <el-button round
          ><span class="iconfont icon-tuichu_huaban1"></span>退出</el-button
        >
      </div>

      <InfoFrame :data="data" :compile="false" :contact="true"></InfoFrame>

      <div class="divider"></div>

      <div class="three">
        <p>我的数据</p>
        <ProjectSelectionList :list="myDatas">
          <template slot="perch">
            <div></div>
            <div></div>
            <div></div>
          </template>
        </ProjectSelectionList>
      </div>
    </div>
    <el-dialog title="修改密码" :visible.sync="dialogVisible" width="450px">
      <el-form
        label-position="right"
        label-width="124px"
        :model="formLabelAlign"
      >
        <el-form-item label="请输入旧密码">
          <el-input v-model="formLabelAlign.oldpwd"></el-input>
        </el-form-item>
        <el-form-item label="请输入新密码">
          <el-input v-model="formLabelAlign.password" show-password></el-input>
        </el-form-item>
        <el-form-item label="请再次输入新密码">
          <el-input v-model="formLabelAlign.password2" show-password></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small " @click="dialogVisible = false"
          >取 消</el-button
        >
        <el-button size="small " type="primary" @click="submitForm"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </AppBar>
</template>

<style lang="less" scoped>
.wrap {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 50px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  background-color: white;
  .divider {
    width: 1px;
    margin: 0 20px;
    // height: 400px;
    align-self: stretch;
    background-color: #dcdfe6;
  }
  .one {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .el-image {
      width: 200px;
      height: 200px;
      border-radius: 100%;
      margin: 5% auto;
    }
    .el-button {
      min-width: 90%;
      margin: 5% auto;

      .icon-tuichu_huaban1 {
        vertical-align: middle;
        margin-right: 5px;
      }
    }
  }
  .three {
    width: 45%;
    height: 100%;
    > p {
      font-size: 22px;
    }
  }
  .el-form {
    min-width: 200px;
    width: 25%;
  }
  .el-avatar {
    width: 100%;
  }
}
</style>

<script>
import ProjectSelectionList from "../../components/ProjectSelectionListA";
import AppBar from "../../components/AppBar";
import InfoFrame from "../../components/OnePersonalInfoFrame";
export default {
  data() {
    return {
      dialogVisible: false,
      formLabelAlign: {
        oldpwd: "",
        password: "",
        password2: ""
      },
      data: {},
      myDatas: {}
    };
  },
  methods: {
    submitForm() {
      this.$common.postInterface(
        "/api/v1.member/editPass",
        this.formLabelAlign,
        this,
        res => {
          if (res.status === "200") {
            this.dialogVisible = false;
            console.log(res);
          } else {
            setmas(res.msg, "error", this);

            // this.$message.error(res.msg);
          }
          // this.myDatas = res.data;
        }
      );
    }
  },
  mounted() {
    this.$common.getInterface(
      "/api/v1.workers/getOne",
      {
        id: sessionStorage.getItem("id") || this.$store.state.personal.id
      },
      this,
      res => {
        this.data = res.data;
      }
    );
    this.$common.getInterface(
      "/api/v1.workers/getProjectCnt",
      {
        admin_id: this.$store.state.personal.id
      },
      this,
      res => {
        this.myDatas = res.data;
      }
    );
  },
  components: {
    ProjectSelectionList,
    AppBar,
    InfoFrame
  }
};
// 提醒弹出框
function setmas(msg,type,vm){
       vm.$message({
                  message:msg,
                  type:type,
                  customClass:"popup"
                  
    });
}
</script>
<style>
.popup {
  width: 400px;
  height: 70px;
  font-size: 26px;
  font-weight: 700;
  position: absolute !important;
  top: 45% !important;
  margin-top: -35px !important;
}
</style>
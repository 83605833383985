<template>
  <div class="list">
    <Item
      v-for="(val, name) in list"
      :key="name"
      :name="name"
      :quantity="val"
      @examine="examine"
    ></Item>

    <!-- 占位用 -->
    <slot name="perch"></slot>
    <!-- 占位用 -->
  </div>
</template>
<style lang="less" scoped>
.list {
  // display: flex;
  // flex-wrap: wrap;
  // justify-content: space-between;
  > div {
    width: 140px;
    margin-top: 20px;
    margin-right: 10px;
    display: inline-block;
  }
}
</style>
<script>
import Item from "./ProjectSelection";
export default {
  props: { list: Object },
  data() {
    return {};
  },
  methods: {
    examine(val) {
      this.$emit("screen",val)
      // console.log(val,"valvalvalvalval");
    }
  },
  components: {
    Item
  }
};
</script>
